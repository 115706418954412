<template>
  <div class="edit-div">
    <label for="name">Name</label>
    <input type="text" id="name" v-model="name" required />
    <br />
    <label for="surname">Surname</label>
    <input type="text" id="surname" v-model="surname" required />
    <br />
    <label for="email">Email</label>
    <input type="email" id="email" v-model="email" required /><br />
    <label for="emailConfirmation">{{ $t("label.emailConfirmation") }}</label>
    <input
      type="email"
      id="emailConfirmation"
      v-model="emailConfirmation"
      required
    />
    <div class="error-message" v-if="emailCheck != ''">
      {{ emailCheck }}
    </div>
    <br v-if="emailCheck == ''" />
    <label for="role">Role</label>
    <input type="text" id="role" v-model="role" required />
    <br />
    <label for="password">Password</label>
    <input
      type="password"
      id="password"
      v-model="password"
      :class="passwordCheckStyle"
    />
    <div class="error-message" v-if="passwordCheck != ''">
      {{ passwordCheck }}
    </div>
    <br v-if="passwordCheck == ''" />
    <label for="passwordConfirmation">Password Confirmation</label>
    <input
      type="password"
      id="passwordConfirmation"
      v-model="passwordConfirmation"
    />
    <br />
    <button
      id="confirmation-button"
      @click="submitEdit"
      :disabled="checkForm"
      class="save-button"
    >
      Save
    </button>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "ClientDisplay",
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      role: "",
      emailConfirmation: "",
    };
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.surname) {
        return true;
      }
      if (!this.email) {
        return true;
      }
      if (!this.role) {
        return true;
      }
      if (this.password.length < 8 && this.password.length > 0) {
        return true;
      }
      if (this.password != this.passwordConfirmation) {
        return true;
      }
      return false;
    },
    passwordCheck: function () {
      if (this.password.length < 8 && this.password.length > 0) {
        return "Password Must be at least 8 caracters";
      }
      if (this.password != this.passwordConfirmation) {
        return "Password ans Password Confirmation must match";
      }
      return "";
    },
    emailCheck: function () {
      if (this.email != this.emailConfirmation) {
        return this.$t("error.emailCheck");
      }
      return "";
    },
    passwordCheckStyle: function () {
      if (this.password.length < 8 && this.password.length > 0) {
        return "errorField";
      }
      if (this.password != this.passwordConfirmation) {
        return "errorField";
      }
      return "normalField";
    },
  },
  methods: {
    submitEdit: async function () {
      try {
        let newData = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          role: this.role,
        };
        if (this.password) {
          newData["password"] = this.password;
        }
        let res = await HandleApi.postTravelAssist(newData);
        if (res.status == 200) {
          this.$router.push("/travelassist/" + res.data);
        }
      } catch (err) {
        window.console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.edit-div {
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  max-width: 90%;
  width: 500px;
  margin: 0 auto;
  border-radius: 10px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}
</style>
